import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import get from "lodash/get"

/* Styles */
import {
  main,
  MainAndSideWrapper,
  HomeContentWrapper,
  sectionWrapper,
  SideColumnHome,
  introSection,
} from "./index.module.css"

/* Components */
import Layout from "../components/layout"
import StickyNav from "../components/sticky-nav/sticky-nav"
import SocialMeta from "../components/social-meta/social-meta"
import Footer from "../components/footer/footer"
import FeaturedGrid from "../components/featured-grid/featured-grid"
import NewestArticles from "../components/newest-articles/newest-articles"
import CampaignGrid from "../components/campaign-grid/campaign-grid"
import HeroMenu from "../components/hero-menu/hero-menu"
import FeaturedHero from "../components/featured-hero/featured-hero"
import SideColumnNew from "../components/side-column/side-column-new"
import Carousel from "../components/carousel/carousel"

import { Default, Mobile } from "../components/media-queries/media-queries"
import HeroCarousel from "../components/hero-carousel/hero-carousel"
import HomeIntro from "../components/home-intro/home-intro"
import SearchProvider from "../components/search-provider/search-provider"
import CtaNewsletterComponent from "../components/cta-newsletter/cta-newsletter"

const SectionWrapper = ({ title, children, className }) => {
  return (
    <section className={`${className || ""} ${sectionWrapper} clearfix`}>
      {title && (
        <h2
          style={{
            fontSize: "32px",
            fontWeight: 700,
            lineHeight: "38px",
            marginBottom: "30px",
          }}
        >
          {title}
        </h2>
      )}
      {children}
    </section>
  )
}

const NewIndexPage = ({ data }) => {
  const articles = data.allContentfulArticle.nodes
  const groups = data.deskArticlesGrids.group
  const navigationDesks = data.navigationDesks.nodes

  const {
    introTitle,
    introDescription,
    introImage,
    whitepapers,
    tools,
    spotifyEmbed,
    featuredHeroes,
    heroMenu: heroList,
    featured: featuredEntries,
    callToActions,
    mediakitTitle,
    mediakitDescription,
    mediakitImage,
    mediakitLink,
    featuredCampaigns,
  } = data.contentfulPage ?? {}

  const excludedEntries = featuredEntries.map((entry) => entry.contentful_id)

  const filteredGroups = groups
    .map((group) => {
      for (const category of group.nodes[0].categories) {
        if (category?.desk?.slug === group.fieldValue) {
          group.priority = category.desk.priority
          group.name = category.desk?.name
          group.slug = group.fieldValue
          group.slug2 = category.desk?.slug2
          group.featured = category.desk?.featured
          group.legacyId = category.desk?.legacyId
        }
      }
      return group
    })
    .sort((a, b) => b.priority - a.priority)

  const siteTitle = get(data, "site.siteMetadata.title")
  const ldJson = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    url: "https://www.mkbservicedesk.nl/",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://www.mkbservicedesk.nl/zoeken/?q={query}",
      "query-input": "required name=query",
    },
  }

  return (
    <Layout>
      <Helmet>
        <html lang="nl" />
        <title>{`${siteTitle} - Helpdesk voor ondernemers, starters en zzp'ers`}</title>
        <meta
          name="description"
          content="MKB Servicedesk biedt informatie voor de ondernemer. Ondernemers kunnen terecht met vragen o.g.v. personeel, financiën, innovatie, in- en externe processen en marketing. De praktische informatie op de website is vooral gericht op de doelgroep: bedrijven kleiner dan 25 werknemers (starters, groeiende bedrijven, ZZP'ers, zelfstandigen zonder personeel)."
        />
        <link rel="canonical" href="https://www.mkbservicedesk.nl" />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>
      <SocialMeta
        title={`${siteTitle} - Helpdesk voor ondernemers, starters en zzp'ers`}
        appendBrand={false}
        description="MKB Servicedesk biedt informatie voor de ondernemer. Ondernemers kunnen terecht met vragen o.g.v. personeel, financiën, innovatie, in- en externe processen en marketing. De praktische informatie op de website is vooral gericht op de doelgroep: bedrijven kleiner dan 25 werknemers (starters, groeiende bedrijven, ZZP'ers, zelfstandigen zonder personeel)."
        url="https://www.mkbservicedesk.nl"
      />

      <StickyNav desks={navigationDesks} />

      <main className={main}>
        <div className={MainAndSideWrapper}>
          <div className={HomeContentWrapper}>
            <SectionWrapper className={introSection}>
              <HomeIntro
                title={introTitle}
                description={introDescription}
                image={introImage}
              />
            </SectionWrapper>

            {/* Featured */}
            <SectionWrapper>
              <FeaturedGrid featuredEntries={featuredEntries} />
            </SectionWrapper>

            <SectionWrapper>
              <CtaNewsletterComponent />
            </SectionWrapper>

            <SectionWrapper title="Nieuws">
              <NewestArticles
                articles={articles}
                excludedEntries={excludedEntries}
              />
            </SectionWrapper>

            <SectionWrapper title="Uitgelicht">
              <Default>
                <CampaignGrid campaigns={featuredCampaigns} />
              </Default>
            </SectionWrapper>

            <Mobile>
              <Carousel items={featuredCampaigns} />
            </Mobile>

            <SectionWrapper title="Zoeken">
              <SearchProvider />
            </SectionWrapper>

            {featuredHeroes?.map((featuredHero) => {
              const featuredArticles = filteredGroups.filter(
                (group) => group.fieldValue === featuredHero.slug
              )[0]

              return (
                <SectionWrapper
                  key={featuredHero.contentful_id}
                  title={featuredHero.name}
                >
                  <FeaturedHero
                    hero={featuredHero}
                    featuredArticles={featuredArticles}
                  />
                </SectionWrapper>
              )
            })}

            <SectionWrapper title="Snel naar">
              <Default>
                <HeroMenu heroList={heroList} />
              </Default>
            </SectionWrapper>
            <Mobile>
              <HeroCarousel items={heroList} />
            </Mobile>
          </div>
          <SideColumnNew
            callToActions={callToActions}
            className={SideColumnHome}
            spotifyEmbed={spotifyEmbed}
            pageContentfulId="index"
            tools={tools}
            whitepapers={whitepapers}
            intro={{
              title: introTitle,
              description: introDescription,
              image: introImage,
            }}
            mediakit={{
              title: mediakitTitle,
              description: mediakitDescription,
              image: mediakitImage,
              link: mediakitLink,
            }}
          />
        </div>
      </main>
      <Footer />
    </Layout>
  )
}

export default NewIndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    contentfulPage(slug: { eq: "index" }) {
      name
      introTitle
      introDescription {
        raw
        references {
          __typename
          ... on ContentfulProductBeta {
            contentful_id
            name
            slug
          }
          ... on ContentfulArticle {
            contentful_id
            id
            legacyId
            title
            slug
            slug2
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
          ... on ContentfulCampaign {
            contentful_id
            name
            slug
          }
          ... on ContentfulDesk {
            contentful_id
            legacyId
            name
            slug
            slug2
          }
          ... on ContentfulDeskCategory {
            contentful_id
            legacyId
            name
            slug
            slug2
          }
          ... on ContentfulDownload {
            contentful_id
            id
            downloadType
            slug
            asset {
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
            formFields
            title
            sponsor {
              name
            }
            image {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 690
                quality: 100
                breakpoints: [350, 690]
              )
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
          ... on ContentfulExternalLink {
            contentful_id
            url
          }
          ... on ContentfulSponsor {
            contentful_id
            name
            url
          }
        }
      }

      featuredCampaigns {
        ... on ContentfulCampaign {
          contentful_id
          id
          name
          slug
          showOnHomepage
          image {
            gatsbyImageData(layout: CONSTRAINED, quality: 100, height: 226)
          }
        }
      }

      introImage {
        gatsbyImageData(layout: CONSTRAINED, quality: 100)
      }

      mediakitTitle
      mediakitLink
      mediakitDescription {
        raw
        references {
          __typename
          ... on ContentfulArticle {
            contentful_id
            id
            legacyId
            title
            slug
            slug2
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
          ... on ContentfulCampaign {
            contentful_id
            name
            slug
          }
          ... on ContentfulDesk {
            contentful_id
            legacyId
            name
            slug
            slug2
          }
          ... on ContentfulDeskCategory {
            contentful_id
            legacyId
            name
            slug
            slug2
          }
          ... on ContentfulDownload {
            contentful_id
            id
            downloadType
            slug
            asset {
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
            formFields
            title
            sponsor {
              name
            }
            image {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 690
                quality: 100
                breakpoints: [350, 690]
              )
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
          ... on ContentfulExternalLink {
            contentful_id
            url
          }
          ... on ContentfulSponsor {
            contentful_id
            name
            url
          }
        }
      }

      mediakitImage {
        gatsbyImageData(layout: CONSTRAINED, quality: 100)
      }

      whitepapers {
        contentful_id
        id
        __typename
        downloadType
        slug
        title
        image {
          title
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            height: 256
            width: 256
          )
        }
      }

      tools {
        contentful_id
        __typename

        title
        slug
        outgrowId

        image {
          title
          gatsbyImageData(
            layout: CONSTRAINED
            height: 256
            width: 256
            quality: 100
          )
        }
      }

      spotifyEmbed {
        id
        title
        link
      }

      heroMenu {
        id
        ... on ContentfulDesk {
          priority
          id
          legacyId
          name
          slug
          slug2
          icon {
            mimeType
            file {
              url
            }
          }
        }
      }

      featuredHeroes {
        ... on ContentfulDesk {
          contentful_id
          __typename
          legacyId
          name
          slug
          slug2
          featured {
            ... on ContentfulArticle {
              contentful_id
              id
              __typename
              legacyId
              title
              slug
              slug2
              image {
                title
                gatsbyImageData(layout: CONSTRAINED, width: 690, quality: 100)
              }
              categories {
                slug2
                desk {
                  slug2
                }
              }
            }
          }
        }
      }

      featured {
        ... on ContentfulArticle {
          contentful_id
          id
          __typename
          legacyId
          title
          slug
          slug2
          image {
            title
            gatsbyImageData(
              layout: CONSTRAINED
              width: 690
              quality: 100
              breakpoints: [350, 690]
            )
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          categories {
            slug2
            desk {
              slug2
            }
          }
        }
        ... on ContentfulDownload {
          contentful_id
          id
          __typename
          downloadType
          slug
          title
          image {
            title
            gatsbyImageData(
              layout: CONSTRAINED
              width: 690
              quality: 100
              breakpoints: [350, 690]
            )
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        ... on ContentfulCampaign {
          contentful_id
          __typename
          name
          slug
          image {
            title
            gatsbyImageData(
              layout: CONSTRAINED
              width: 690
              quality: 100
              breakpoints: [350, 690]
            )
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        ... on ContentfulDesk {
          contentful_id
          __typename
          legacyId
          name
          slug
          slug2
          image {
            title
            gatsbyImageData(
              layout: CONSTRAINED
              width: 690
              quality: 100
              breakpoints: [350, 690]
            )
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        ... on ContentfulDeskCategory {
          contentful_id
          __typename
          legacyId
          name
          slug
          slug2
        }
      }

      callToActions {
        __typename
        ... on ContentfulDownload {
          contentful_id
          id
          showSidebarCtaImage
          __typename
          downloadType
          slug
          title
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          image {
            title
            gatsbyImageData(
              layout: CONSTRAINED
              width: 690
              quality: 100
              breakpoints: [350, 690]
            )
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        ... on ContentfulProductBeta {
          showOnOverview
          slug
          contentful_id
          name

          callToActionTitle
          callToActionDescription {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          callToActionButtonText
          callToActionImage {
            gatsbyImageData(layout: FULL_WIDTH)
            title
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }

          introDescription {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          introImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          overviewButtonText
          overviewImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        ... on ContentfulExternalLink {
          contentful_id
          title
          url
          linkText
          showSidebarCtaImage
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 295
              placeholder: BLURRED
              quality: 100
            )
          }
        }
      }
      tags {
        name
        legacyId
        slug
      }
      articles {
        title
        subtitle
        slug
        slug2
        legacyId
        publishDate(formatString: "DD MMMM YYYY", locale: "nl")
        updateDate(formatString: "DD MMMM YYYY", locale: "nl")
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 1180
            placeholder: BLURRED
          )
        }
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        categories {
          name
          slug
          slug2
          legacyId
          desk {
            name
            slug
            slug2
            legacyId
          }
        }
      }
    }

    deskArticlesGrids: allContentfulArticle(
      sort: { fields: updateDate, order: DESC }
      limit: 200
    ) {
      group(field: categories___desk___slug) {
        nodes {
          id
          contentful_id
          slug
          slug2
          publishDate
          legacyId
          title
          subtitle
          image {
            title
            gatsbyImageData(
              layout: CONSTRAINED
              width: 256
              height: 256
              placeholder: BLURRED
            )
          }
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          categories {
            legacyId
            slug
            slug2
            desk {
              name
              legacyId
              slug
              slug2
              priority
              featured {
                categories {
                  legacyId
                  slug
                  slug2
                  desk {
                    legacyId
                    slug
                    slug2
                  }
                }
                slug
                slug2
                legacyId
                title
                subtitle
                image {
                  title
                  gatsbyImageData(
                    layout: CONSTRAINED
                    width: 800
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
        fieldValue
      }
    }

    allContentfulArticle(
      limit: 10
      sort: { fields: updateDate, order: DESC }
      filter: {
        categories: { elemMatch: { desk: { slug2: { eq: "nieuws" } } } }
      }
    ) {
      nodes {
        id
        contentful_id
        title
        subtitle
        slug
        slug2
        legacyId
        publishDate(formatString: "DD MMMM YYYY", locale: "nl")
        updateDate(formatString: "DD MMMM YYYY", locale: "nl")
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 256
            height: 256
            placeholder: BLURRED
          )
        }
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        categories {
          name
          slug
          slug2
          legacyId
          desk {
            name
            slug
            slug2
            legacyId
          }
        }
      }
    }

    navigationDesks: allContentfulDesk(
      sort: { fields: sortNavigation, order: ASC }
    ) {
      nodes {
        id
        name
        slug2
        sortNavigation
        ctaNavigation {
          image {
            title
            gatsbyImageData(layout: CONSTRAINED, width: 1180)
          }
          title
          slug
          categories {
            slug2
            desk {
              slug2
            }
          }
        }
        deskcategory {
          id
          name
          slug2
          desk {
            id
            name
            slug2
          }
          article {
            id
            title
            slug2
            updateDate
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
        }
      }
    }
  }
`
